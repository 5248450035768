import cohort_page_img from '../../assets/images/accelplace-demo-feb-cohort-page.png';
import placement_page_img from '../../assets/images/accelplace-demo-placements-page.png';
import popup_img from '../../assets/images/accelplace-demo-popup.png';
import cohorts_overview_img from '../../assets/images/accelplace-demo-cohorts-overview-page.png';
import login_page_img from '../../assets/images/accelplace-demo-login-page.png';
// import stargazer_html from "./stargazer/stargazer.html";
import '../../styles/common.css';


function Project() 
{
    return (
        <div className="pb-5 pt-3">
            <div className="d-flex justify-content-between">
                <h2 className="">Projects</h2>
                <a
                    className="btn btn-outline-dark col-2 mt-2 mb-2"
                    id="github-connect-btn"
                    href="https://github.com/thomaspelegrin"
                    target="_blank"
                    rel="noreferrer">
                    Connect on GitHub <i className="bi bi-github"></i>
                </a>
            </div>
            <div className="d-flex flex-column gap-5">
                <section className="border rounded-xl shadow" id="accelplace">
                    <h3 className="text-underline bg-gradient rounded-xl px-3 pt-3 p-1">AccelPlace (web application)</h3>
                    <div className="carousel slide" id="accelplace-carousel" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-bs-target="#accelplace-carousel" data-bs-slide-to="0"></li>
                            <li data-bs-target="#accelplace-carousel" data-bs-slide-to="1" className="active"></li>
                            <li data-bs-target="#accelplace-carousel" data-bs-slide-to="2"></li>
                            <li data-bs-target="#accelplace-carousel" data-bs-slide-to="3"></li>
                            <li data-bs-target="#accelplace-carousel" data-bs-slide-to="4"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item">
                                <img className="d-block img-fluid w-100" src={ login_page_img } alt="Login page" />
                                <div className="carousel-caption d-none d-md-block bg-secondary rounded-xl">
                                    <h5>Login page</h5>
                                </div>
                            </div>
                            <div className="carousel-item active">
                                <img className="d-block img-fluid w-100" src={ cohort_page_img } alt="February Cohort - MH/OB Clinical placements table" />
                                <div className="carousel-caption d-none d-md-block bg-secondary rounded-xl">
                                    <h5>February Cohort - MH/OB Clinical</h5>
                                    <h6>Here coordinators can manage places on a more granular level</h6>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img className="d-block img-fluid w-100" src={ placement_page_img } alt="Placements page" />
                                <div className="carousel-caption d-none d-md-block bg-secondary rounded-xl">
                                    <h5>Placements page - shows all of the student's clinical site placements</h5>
                                    <h6>(filters are applied in this screenshot)</h6>
                                </div>
                            </div>

                            <div className="carousel-item">
                                <img className="d-block img-fluid w-100" src={ popup_img } alt="Popups and overlays" />
                                <div className="carousel-caption d-none d-md-block bg-secondary rounded-xl">
                                    <h5>Demo of the popup/overlay system</h5>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img className="d-block img-fluid w-100" src={ cohorts_overview_img } alt="Cohorts overview" />
                                <div className="carousel-caption d-none d-md-block bg-secondary rounded-xl">
                                    <h5>Cohorts overview page</h5>
                                    <h6>Coordinators can view high-level stats about the 3 active cohorts</h6>
                                </div>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#accelplace-carousel" role="button" data-bs-slide="prev">
                            <i className="bi bi-arrow-left-circle-fill h1"></i>
                        </a>
                        <a className="carousel-control-next" href="#accelplace-carousel" role="button" data-bs-slide="next">
                            <i className="bi bi-arrow-right-circle-fill h1"></i>
                        </a>
                    </div>
                    <div className="card-body bg-muted px-5 pb-4">
                        <p>
                            AccelPlace is a JavaScript (Node.js) project I undertook in my Web Software Development course during my final semester of college. The project aimed to create a web application to assist clinical coordinators (“the client”) from the University of Wisconsin–Oshkosh Accelerated Nursing program track and place students at five clinical sites throughout a 2-year period to satisfy the requirements of the program. In particular, the client was looking to transition from using simple Microsoft Word documents to a more organized and easier way to interact with the years of accumulated data on students, preceptors, placements, clinical sites, contacts, course information, contract information, and cohort information.
                        </p>
                        <p>
                            The backend of the project was built within a Node.js environment and used the Express.js web framework. Embedded JavaScript (EJS) templating was used to render the HTML pages and tables before sending them to the client browser. The project involved me designing a RESTful API to interact with the server and writing all of the code necessary for the project. I designed the relational database using PostgreSQL and CockroachDB. The database is composed of 12 tables and 19 views. In total, the entire project consists of over 10,000 lines of JavaScript, HTML, CSS, and SQL. Almost everything thing, except for a few backend JavaScript libraries, was written from scratch in the period of approximately 1-2 months.
                        </p>
                        <p>
                            If you would like to learn more or view a demo version of this project, please <a href="Contact">contact me</a>.
                        </p>
                    </div>
                </section>
                <section className="border rounded-xl m-2 shadow" id="other">
                    <h3 className="text-underline bg-gradient rounded-xl px-3 pt-3 p-1">Miscellaneous</h3>
                    <div className="d-flex col-12">
                        <iframe
                            className="col-6"
                            src="stargazer.html"
                            height="600px"
                            width="600px"
                            style={ { border: 'none' } }
                            title="Embedded HTML"
                        />
                        <div className="bg-muted px-5 pb-4 col-6">
                            <h4 className="text-center">More:</h4>
                            <ul className="fs-4">
                                <li className="my-3">
                                    <a className="link-primary"
                                        title="A simplified Unix shell - supports piping, I/O redirection, and asynchronous program execution"
                                        href="https://github.com/thomaspelegrin/unixshell" target="_blank" rel="noreferrer">Unix Shell</a> <strong>(c)</strong>
                                    <ul>
                                        <li>A simplified Unix shell that I implemented using the POSIX unistd.h library</li>
                                        <li>Supports piping, I/O redirection, and asynchronous program execution</li>
                                    </ul>
                                </li>
                                <li className="my-3">
                                    <a className="link-primary"
                                        href="https://github.com/thomaspelegrin/Microputer" target="_blank" rel="noreferrer">Microputer</a> <strong>(c)</strong>
                                    <ul>
                                        <li>Program that simulates a CPU executing assembly (as machine code)</li>
                                    </ul>
                                </li>
                                <li className="my-3">
                                    <a className="link-primary"
                                        href="https://github.com/thomaspelegrin/P2P-Project" target="_blank" rel="noreferrer">P2P Client</a> <strong>(Python)</strong>
                                    <ul>
                                        <li> A peer-to-peer file-sharing client in Python utilizing both TCP and UDP sockets</li>
                                        <li>Supports searches, message transfers, and non-blocking file transfers</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Project;;