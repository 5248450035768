import React from 'react';
import
{
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import About from '../../components/about/About';
import Hobbies from '../../components/hobbies/Hobbies';
import Projects from '../../components/projects/Projects';
import Contact from '../../components/contact/Contact';


function Layout( { children } )
{
    return (
        <div>
            <Navbar />
            <main
                className="container">
                <Router>

                    { children }
                    <Routes>
                        <Route exact path="/" element={ <About /> } />
                        <Route path="/about" element={ <About /> } />
                        <Route path="/hobbies" element={ <Hobbies /> } />
                        <Route path="/projects" element={ <Projects /> } />
                        <Route path="/contact" element={ <Contact /> } />
                    </Routes>
                </Router>
            </main>
            <Footer />
        </div>
    );
};

export default Layout;