import clint_eastwood_img from '../../assets/images/hobbies/art/clint.png';
import elo_img from '../../assets/images/hobbies/art/elo.png';
import raptor_img from '../../assets/images/hobbies/art/raptor.png';
import solar_eclipse_img from '../../assets/images/hobbies/outdoors/solar-eclipse.png';
import up_mi_1_img from '../../assets/images/hobbies/outdoors/upper-michigan-1.png';
import up_mi_2_img from '../../assets/images/hobbies/outdoors/upper-michigan-2.png';
import up_mi_3_img from '../../assets/images/hobbies/outdoors/upper-michigan-3.png';


function Hobbies() 
{
    return (
        <div className="pb-5 pt-3">
            <h2 className="">Hobbies</h2>
            <div className="d-flex flex-column gap-5">
                <section className="border rounded-xl m-2 shadow pb-5" id="other">
                    <h3 className="text-underline bg-gradient rounded-xl px-3 pt-3 p-1">Drawing & Painting</h3>
                    <div className="d-flex justify-content-center">
                        <div className="d-flex gap-3 overflow-scroll">
                            <img className="d-block img-fluid" width="300px" src={ clint_eastwood_img } alt="" />
                            <img className="d-block img-fluid" width="300px" src={ elo_img } alt="" />
                            <img className="d-block img-fluid" width="500px" src={ raptor_img } alt="" />
                        </div>
                    </div>
                </section>
                <section className="border rounded-xl shadow col-12 d-flex gap-1 pb-5" id="outdoors">
                    <div className="col-6">
                        <h3 className="text-underline bg-gradient rounded-xl px-3 pt-3 p-1">Outdoors (hiking, etc)</h3>
                        <div className="carousel slide" id="outdoors-carousel" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-bs-target="#outdoors-carousel" data-bs-slide-to="0" className="active"></li>
                                <li data-bs-target="#outdoors-carousel" data-bs-slide-to="1"></li>
                                <li data-bs-target="#outdoors-carousel" data-bs-slide-to="2"></li>
                                <li data-bs-target="#outdoors-carousel" data-bs-slide-to="3"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img className="d-block img-fluid w-100" src={ solar_eclipse_img } alt="Solar eclipse from Southern Illinois, April 8, 2024" />
                                </div>
                                <div className="carousel-item">
                                    <img className="d-block img-fluid w-100" src={ up_mi_3_img } alt="" />
                                </div>
                                <div className="carousel-item">
                                    <img className="d-block img-fluid w-100" src={ up_mi_1_img } alt="" />
                                </div>
                                <div className="carousel-item">
                                    <img className="d-block img-fluid w-100" src={ up_mi_2_img } alt="" />
                                </div>
                            </div>
                            <a className="carousel-control-prev" href="#outdoors-carousel" role="button" data-bs-slide="prev">
                                <i className="bi bi-arrow-left-circle-fill h1"></i>
                            </a>
                            <a className="carousel-control-next" href="#outdoors-carousel" role="button" data-bs-slide="next">
                                <i className="bi bi-arrow-right-circle-fill h1"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-6 p-5">
                        <p>
                            All of these photos (except for the April 8, 2024 solar eclipse image) were taken at parks within a few hours drive from the Fox Cities. I would highly recommend the Driftless Area to anyone who hasn't gone before. It has some of the most amazing scenery in this part of the country and is a great place to hike around. A few friends of mine (spotted in some of the photos) and myself love to visit this part of the midwest during the summer months.
                        </p>
                        <p>
                            There is one photo in here from Door County which was a little awe-inspiring, especially during the winter.  The water from the waves of the Lake Michigan causes everything close enough to the coast to be coated in a layer of ice. It's a pretty incredible sight.
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Hobbies;