import mePng from '../../assets/images/me.png';
import './About.css';

function About()
{
    return (
        <div className="shadow-lg p-4 pt-0" id="about-wrapper">
            <h2 className="rounded-xl p-3 bg-gradient">Intro</h2>
            <div className="d-flex">
                <div className="d-flex flex-column col-4 gap-4 align-items-center">
                    <img
                        className="img-fluid"
                        id="headshot-portrait-img"
                        src={ mePng }
                        alt="Me"
                    // width="300"
                    />
                    <div className="d-flex flex-column col-12 gap-3 mt-4"
                        id="connect-btns-wrapper">
                        {/* FIXME: add js to handle icon changing color when hover */ }
                        <a
                            className="btn btn-outline-dark col-8 offset-2"
                            id="github-connect-btn"
                            href="https://github.com/thomaspelegrin"
                            target="_blank"
                            rel="noreferrer">
                            Connect on GitHub <i className="bi bi-github"></i>
                        </a>
                        {/* FIXME: add js to handle icon changing color when hover */ }
                        <a
                            className="btn btn-outline-primary col-8 offset-2"
                            id="linkedin-connect-btn"
                            href="https://www.linkedin.com/in/thomaspelegrin/"
                            target="_blank"
                            rel="noreferrer">
                            Connect on LinkedIn <i className="bi bi-linkedin"></i>
                        </a>

                    </div>
                </div>
                <div className="col-8" id="about-me-paragraph-wrapper">
                    <p>
                        Hello! I'm Thomas.
                    </p>
                    <p>
                        I attended the University of Wisconsin-Oshkosh where I graduated <em>summa cum laude</em> with a Bachelor's degree in Computer Science. I currently work as a Software Engineer in the Fox Cities writing code for .NET applications. I am highly capable with C-flavored languages, including C, C++, C#, Java, and JavaScript, in addition to various flavors of SQL (PostgreSQL, MySQL, T-SQL).
                    </p>
                    <p>
                        I have lived in the Fox Cities all of my life, and despite the cold winters, I very much enjoy the area. Wisconsin is a state full of friendly people and beautiful scenery, especially in the south-western portion of the state known as the Midwest's Driftless area.
                    </p>
                    <p>
                        I have a broad curiosity about all sorts of topics and thoroughly enjoy learning more whenever and wherever I can. Of course, I have a deep interest in computers and computing-related technology, but I also have a deep personal interest in history and geography. Some of my favorite hobbies include biking, drawing, reading, and playing some old-school strategy games (Age of Empires & Civilization).
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
