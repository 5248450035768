function Navbar()
{
    return (
        <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark bg-dark box-shadow mb-0">
            <div className="container-fluid">
                <a className="navbar-brand font-weight-bolder text-sm-caps" href="/">Thomas Pelegrin</a>

                <div>
                    <div className="navbar-collapse collapse d-sm-inline-flex justify-content-between">
                        <ul className="navbar-nav flex-grow-1">
                            <li className="nav-item m-0">
                                <a className="nav-link" href="/About">About</a>
                            </li>
                            <li className="nav-item m-0">
                                <a className="nav-link" href="/Hobbies">Hobbies</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/Projects">Projects</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/Contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
    );
}
export default Navbar;



